
import { defineComponent } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmStatus,
    TmButton,
  },
  setup() {
    const items = [
      {
        title: 'Name',
        text: 'Textmagic',
      },
      {
        title: 'Created',
        text: '28 Feb 2022',
      },
      {
        title: 'Brand ID',
        text: 'B5LSBOU',
      },
      {
        title: 'Campaigns',
        text: '6',
      },
      {
        title: 'Status',
        status: {
          color: 'green',
          name: 'verified',
          outline: true,
        },
      },
    ]

    return { items }
  },
})
