
import { defineComponent } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import Chat10dlcBrand from '@/components/pages/chats/settings/10dlc/Chat10dlcBrand.vue'
import Chat10dlcNumbers from '@/components/pages/chats/settings/10dlc/Chat10dlcNumbers.vue'
import Chat10dlcCampaigns from '@/components/pages/chats/settings/10dlc/Chat10dlcCampaigns.vue'

export default defineComponent({
  components: {
    DetailsHero,
    Chat10dlcCampaigns,
    Chat10dlcBrand,
    Chat10dlcNumbers,
    ChatsSettingsPage,
    TmAlert,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: '10DLC registration' },
    ]

    return {
      breadcrumbs,
    }
  },
})
