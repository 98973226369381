
import { defineComponent } from 'vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import Chat10dlcNumbersDropdown from '@/components/pages/chats/settings/10dlc/numbers/Chat10dlcNumbersDropdown.vue'
import { useByoc } from '@/compositions/services/useByoc'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmFlag,
    TmBadge,
    TmTable,
    TmButton,
    TmStatus,
    Chat10dlcNumbersDropdown,
  },
  setup() {
    const { openEditLabelModal } = useByoc()
    const { openModal } = useModals()

    return {
      openModal,
      openEditLabelModal,
    }
  },
})
