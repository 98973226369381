
import { defineComponent, ref } from 'vue'
import Chat10dlcCampaignsFilter from '@/components/pages/chats/settings/10dlc/campaigns/Chat10dlcCampaignsFilter.vue'
import Chat10dlcCampaignsTable from '@/components/pages/chats/settings/10dlc/campaigns/Chat10dlcCampaignsTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    Chat10dlcCampaignsFilter,
    Chat10dlcCampaignsTable,
  },
  setup() {
    const search = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Campaign name', value: 'name-slot', hideSortable: true, width: 230 },
      { text: 'Campaign id', hideSortable: true, value: 'id' },
      { text: 'Use case', value: 'use-case-slot', hideSortable: true, width: 120 },
      { text: 'Status', hideSortable: true, value: 'status-slot' },
      { text: 'Next renewal', hideSortable: true, value: 'renewal' },
      { text: '', hideSortable: true, value: 'issues-slot' },
    ])
    const tableItems = [
      {
        name: 'Appointment reminders for special clients',
        id: 'CKC4ZRT',
        useCase: 'Higher Education Improve',
        status: {
          name: 'action required',
          color: 'orange',
          outline: false,
        },
        renewal: '-',
        issues: true,
      },
      {
        name: 'Marketing use case',
        id: 'CKLEY6G',
        useCase: 'Marketing',
        status: {
          name: 'active',
          color: 'green',
          outline: true,
        },
        renewal: '17 Dec 2022',
        issues: false,
      },
    ]

    return {
      tableItems,
      tableHeaders,
      search,
    }
  },
})
