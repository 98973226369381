
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import Chat10dlcCampaignsDropdown from '@/components/pages/chats/settings/10dlc/campaigns/Chat10dlcCampaignsDropdown.vue'

export default defineComponent({
  components: {
    TmTable,
    TmButton,
    TmStatus,
    TmEllipsis,
    Chat10dlcCampaignsDropdown,
  },
})
