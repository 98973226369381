
import { defineComponent, ref } from 'vue'
import Chat10dlcNumbersFilter from '@/components/pages/chats/settings/10dlc/numbers/Chat10dlcNumbersFilter.vue'
import Chat10dlcNumbersTable from '@/components/pages/chats/settings/10dlc/numbers/Chat10dlcNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'

export default defineComponent({
  components: {
    Chat10dlcNumbersFilter,
    Chat10dlcNumbersTable,
  },
  setup() {
    const search = ref()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Textmagic number', value: 'number-slot', hideSortable: true, width: 230 },
      { text: 'Label', hideSortable: true, value: 'label-slot' },
      { text: 'Linked to campaign', value: 'campaign-slot', hideSortable: true, width: 120 },
      { text: 'Status', hideSortable: true, value: 'status-slot' },
    ])
    const tableItems = ref(getTableData('dlcNumbers'))

    return {
      tableHeaders,
      tableItems,
      search,
    }
  },
})
